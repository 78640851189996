import React from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../lib/articleUtils'
import { x } from '@xstyled/styled-components'
import ArticleCard from '../app/components/ArticleCard'
import { formatAgo } from '../lib/dateUtils'
import ImageOrNoImage from '../app/components/ui/Image'
import NoImage from '../../assets/images/general/no-image.png'
import StyledLink from '../app/components/ui/Link'

const OpinionArticlesNoImage = ({ article, hoverColor, visitorId, sessionId, section, clickSource, color }) => {
  return (
    <StyledLink to={'/' + getCanonical(article)} article={article} display='flex' spaceX={10} flexDirection={{ sm: 'unset', lg: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
      <x.div display={{ lg: 'none' }}>
        <ImageOrNoImage image={getImage(article)} width={getImageSize('normal')} alt={getTeaser(article)} shape='square' noImage={NoImage} w={{ sm: '100px', lg: '100%' }} />
      </x.div>
      <x.div flex='1 1 0'>
        <x.h6 text='h6'>{getTeaser(article)}</x.h6>
        <x.p text='meta' color='meta' mt={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
      </x.div>
    </StyledLink>
  )
}

const OpinionArticles = ({ article, hoverColor, visitorId, sessionId, section, clickSource, color }) => {
  return (
    <StyledLink to={'/' + getCanonical(article)} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
      <x.div>
        <ImageOrNoImage image={getImage(article)} width={getImageSize('normal')} alt={getTeaser(article)} shape='square' noImage={NoImage} w={{ sm: '100px', lg: '100px' }} />
      </x.div>
      <x.div flex='1 1 0'>
        <x.h6 text='h6'>{getTeaser(article)}</x.h6>
        <x.p text='meta' color='meta' mt={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
      </x.div>
    </StyledLink>
  )
}

const Template = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath, visitorId, sessionId, color, hoverColor, isOpinion }) => {
  const clickSource = 'TV Box'
  return (
    <>
      {articles[0] && articles[1] &&
        <x.div display='flex' flexDirection='column' position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
          <ArticleCard
            pb={20} size='hero' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'
            headline={(p) => (<x.h1 text='h1'>{p.children}</x.h1>)} showAbstract showImage href={'/' + getCanonical(articles[0])}
            article={articles[0]}
            visitorId={visitorId} sessionId={sessionId} section='news'
            color={color}
            hoverColor={hoverColor}
          />
          <x.article pt={20}>
            <StyledLink to={'/' + getCanonical(articles[1])} display='flex' spaceX={{ sm: '0', article: '10px' }} flexDirection={{ sm: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
              <x.div flex='1 1 0'>
                <x.h4 text='h4'>{getTeaser(articles[1])}</x.h4>
                <x.p text='meta' color='meta' my={8}>By {articles[1].author} | Published {formatAgo(articles[1].published, true)}</x.p>
                <x.p text='small'>{articles[1].abstract}</x.p>
              </x.div>
              <x.div mb={{ sm: '10', article: '0' }}>
                <ImageOrNoImage image={getImage(articles[1])} w={{ sm: '100%', article: 'auto' }} mb={10} width={getImageSize('hero')} display={{ sm: 'none', lg: 'block', article: 'none' }} alt={getTeaser(articles[1])} shape='16x9' noImage={NoImage} />
                <ImageOrNoImage image={getImage(articles[1])} w={{ sm: '100%', article: 'auto' }} mb={10} width={getImageSize('primary')} display={{ sm: 'block', lg: 'none', article: 'block' }} alt={getTeaser(articles[1])} shape='16x9' noImage={NoImage} />
              </x.div>
            </StyledLink>
          </x.article>
        </x.div>}
      <x.div position='relative' p={{ sm: '20px 0', lg: '0' }} className='divider-right' borderWidth='0'>
        {articles[2] &&
          <x.article pb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            {!isOpinion &&
              <StyledLink to={'/' + getCanonical(articles[2])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div flex='1 1 0'>
                  <x.h6 text='h6'>{getTeaser(articles[2])}</x.h6>
                  <x.p text='meta' color='meta' mt={8}>By {articles[2].author} | Published {formatAgo(articles[2].published, true)}</x.p>
                </x.div>
                <x.div>
                  <ImageOrNoImage image={getImage(articles[2])} width={getImageSize('normal')} alt={getTeaser(articles[2])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticles
                article={articles[2]} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>}
        {articles[3] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            {!isOpinion &&
              <StyledLink to={'/' + getCanonical(articles[3])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div flex='1 1 0'>
                  <x.h6 text='h6'>{getTeaser(articles[3])}</x.h6>
                  <x.p text='meta' color='meta' mt={8}>By {articles[3].author} | Published {formatAgo(articles[3].published, true)}</x.p>
                </x.div>
                <x.div>
                  <ImageOrNoImage image={getImage(articles[3])} width={getImageSize('normal')} alt={getTeaser(articles[3])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticles
                article={articles[3]} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>}
        {articles[4] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            {!isOpinion &&
              <StyledLink to={'/' + getCanonical(articles[4])} article={articles[4]} display='flex' spaceX={10} flexDirection={{ sm: 'unset', lg: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div flex='1 1 0'>
                  <x.h6 text='h6'>{getTeaser(articles[4])}</x.h6>
                  <x.p text='meta' color='meta' mt={8}>By {articles[4].author} | Published {formatAgo(articles[4].published, true)}</x.p>
                </x.div>
                <x.div display={{ lg: 'none' }}>
                  <ImageOrNoImage image={getImage(articles[4])} width={getImageSize('normal')} alt={getTeaser(articles[4])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100%' }} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticlesNoImage
                article={articles[4]} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>}
        {articles[5] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            {!isOpinion &&
              <StyledLink to={'/' + getCanonical(articles[5])} article={articles[5]} display='flex' spaceX={10} flexDirection={{ sm: 'unset', lg: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div flex='1 1 0'>
                  <x.h6 text='h6'>{getTeaser(articles[5])}</x.h6>
                  <x.p text='meta' color='meta' mt={8}>By {articles[5].author} | Published {formatAgo(articles[5].published, true)}</x.p>
                </x.div>
                <x.div display={{ lg: 'none' }}>
                  <ImageOrNoImage image={getImage(articles[5])} width={getImageSize('normal')} alt={getTeaser(articles[5])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100%' }} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticlesNoImage
                article={articles[5]} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>}
        {articles[6] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            {!isOpinion &&
              <StyledLink to={'/' + getCanonical(articles[6])} article={articles[6]} display='flex' spaceX={10} flexDirection={{ sm: 'unset', lg: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div flex='1 1 0'>
                  <x.h6 text='h6'>{getTeaser(articles[6])}</x.h6>
                  <x.p text='meta' color='meta' mt={8}>By {articles[6].author} | Published {formatAgo(articles[6].published, true)}</x.p>
                </x.div>
                <x.div display={{ lg: 'none' }}>
                  <ImageOrNoImage image={getImage(articles[6])} width={getImageSize('normal')} alt={getTeaser(articles[6])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100%' }} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticlesNoImage
                article={articles[6]} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>}
        {articles[7] &&
          <x.article pt={20}>
            {!isOpinion &&
              <StyledLink to={'/' + getCanonical(articles[7])} display='flex' spaceX={10} flexDirection={{ sm: 'unset', lg: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource} color={color} hoverColor={hoverColor}>
                <x.div flex='1 1 0'>
                  <x.h6 text='h6'>{getTeaser(articles[7])}</x.h6>
                  <x.p text='meta' color='meta' mt={8}>By {articles[7].author} | Published {formatAgo(articles[7].published, true)}</x.p>
                </x.div>
                <x.div display={{ lg: 'none' }}>
                  <ImageOrNoImage image={getImage(articles[7])} width={getImageSize('normal')} alt={getTeaser(articles[7])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100%' }} />
                </x.div>
              </StyledLink>}
            {isOpinion &&
              <OpinionArticlesNoImage
                article={articles[7]} hoverColor={hoverColor} visitorId={visitorId} sessionId={sessionId} section={section} clickSource={clickSource} color={color}
              />}
          </x.article>}
      </x.div>
    </>
  )
}

const HomePageTVBox = (props) => {
  return (
    <Template {...props} />
  )
}

export default HomePageTVBox
