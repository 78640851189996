import { Tab, useTabState } from '../components/ui/Tabs'
import MostReadArticles from './MostReadArticles'
import EditorsChoiceArticles from './EditorsChoiceArticles'

export const MostReadWidget = props => {
  const tab = useTabState({ selectedId: 'most-read' })
  return (
    <>
      <Tab.List ariaLabel='Tabs' {...tab} bg='ltGrey' px={15} py={8} mb={20} display='flex' justifyContent='space-between' flexWrap='nowrap'>
        <Tab.Tab {...tab} id='most-read'>
          Most Read
        </Tab.Tab>
        <Tab.Tab {...tab} id='editors-choice'>
          Editor's Choice
        </Tab.Tab>
      </Tab.List>
      <Tab.Panel {...tab} id='most-read' pb={20} mb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <MostReadArticles {...props} />
      </Tab.Panel>
      <Tab.Panel {...tab} id='editors-choice' pb={20} mb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
        <EditorsChoiceArticles {...props} />
      </Tab.Panel>
    </>
  )
}

export default MostReadWidget
