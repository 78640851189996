import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchTrendingArticles } from '../../store/trendingArticles'
import { getCanonical, getImage, getTeaser } from '../../lib/articleUtils'
import StyledLink from '../components/ui/Link'
import ImageOrNoImage from './ui/Image'
import NoImage from '../../../assets/images/general/no-image.png'
import Articles from '../components/Articles'
import { getSessionId, getVisitorId } from '../../store/article'

const Trends = (props) => {
  const clickSource = 'Trend Bar'
  return (
  <>
    <x.div maxWidth={1220} w='100%' mx='auto' mt={50} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20} display='flex' alignItems={{ sm: 'none', lg: 'center' }} flexDirection={{ sm:'column', lg: 'row' }} pt={{ sm: '0', lg: '20px' }}>
      <x.h3 text='h3' mr={{ sm: '0', lg: '50px' }} mb={{ sm: '10', lg: '0' }} w={{ sm: '100%', lg: 'auto' }}>
        <StyledLink to='/trends' visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} color={props.color} hoverColor={props.hoverColor}>Trending on IOL</StyledLink>
      </x.h3>
      <x.div display='flex' flexWrap='wrap'>
        {props.sectionsLabels.map(trend => (
          <StyledLink to={'/' + trend.slug} key={trend.slug} text={props.darkMode ? 'trendLinkDarMode' : 'trendLink'} color={props.color} hoverColor={props.hoverColor} backgroundColor={props.darkMode ? '#434343' : '#EFF1F1'} borderRadius='100px' p='4px 12px' mr={10} mb={{ sm: '10px', lg: '0' }} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>{trend.label}</StyledLink>
        ))}
      </x.div>
    </x.div>
    <x.div maxWidth={1220} w='100%' mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }} pt={{ sm: '0', lg: '20px' }} className='trendsbar'>
      {props.articles.slice(0, 4).map((article, i) => (
        <x.article key={article.uuid} position='relative' className={i < 3 ? 'divider-right' : ''} p={{ sm: '20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
          <StyledLink article={article} to={getCanonical(article)} display='flex' spaceX={10} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource} color={props.color} hoverColor={props.hoverColor}>
            <x.div flex='1 1 0'>
              <x.h6 text='h6' mb={8}>{getTeaser(article)}</x.h6>
            </x.div>
            <x.div>
              <ImageOrNoImage image={getImage(article)} width={80} alt={getTeaser(article)} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '80px' }} />
            </x.div>
          </StyledLink>
        </x.article>
      ))}
    </x.div>
  </>
)}

const TrendsBar = props => (
  <Articles className={props.className} section='trendingarticles' page0Limit={4} pageSize={4} fetchArticles={props.fetchTrendingArticles}
    noImage={NoImage} fetchMoreArticles={() => { }} articles={props.articles} component={Trends} {...props} />
)

const mapStateToProps = (state) => ({ articles: state.trendingArticles, sessionId: getSessionId(state), visitorId: getVisitorId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchTrendingArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TrendsBar)
