import React from 'react'
import StyledLink from '../components/ui/Link'
import styled, { x, system } from '@xstyled/styled-components'
import { formatAgo } from '../../lib/dateUtils'
import { getTeaser } from '../../lib/articleUtils'
import ImageOrNoImage from './ui/Image'
import NoImage from '../../../assets/images/general/no-image.png'

const ImageSizes = {
  hero: 560,
  primary: 240,
  normal: 100
}

const defaultHeadline = (p) => (<x.h1 text='h1'>{p.children}</x.h1>) (<x.h6 text='h6'>{p.children}</x.h6>)

export function ArticleCardLayout ({ article, href, shape, showImage, showAbstract, image, size, headline, ...rest }) {
  const ShowImage = showImage && (article.image || (!!article.images && article.images.length))
  const Image = image || article.image || (article.images && article.images[0]) || (article.videos && { url: article.videos[0].thumbnailURL })
  const ImageSize = ImageSizes[size] || size
  const Headline = headline || defaultHeadline
  const clickSource = 'Article Card'

  return (
    <x.article pb={20} {...rest}>
      <StyledLink article={article} to={href} text='secondary' visitorId={rest.visitorId} sessionId={rest.sessionId} section={rest.section} source={clickSource} color={rest.color} hoverColor={rest.hoverColor}>
        {ShowImage &&
          <ImageOrNoImage image={Image} w='100%' mb={10} width={ImageSize} alt={getTeaser(article)} shape={shape || '16x9'} noImage={NoImage} />
        }
        <Headline>{getTeaser(article)}</Headline>
        <x.p text='meta' color={rest.color || 'meta'} my={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
        {showAbstract &&
          <x.p text='large'>{article.abstract}</x.p>
        }
      </StyledLink>
    </x.article>
  )
}

const ArticleCard = styled(ArticleCardLayout)`
  ${system}
`
export default ArticleCard
