import { memo } from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Articles as IONArticles } from 'ion-article-cmp'
import Helmet from 'react-helmet'
import { pageView } from '../../store/app'
import deepCompare from '../../lib/deepCompare'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const MoreFromButton = styled.button`
  text-transform: uppercase;
  padding: 10px 20px;
  color: #FFF;
  background-color: grey;
  transition: all linear .3s;
  &:hover {
    background-color: black;
  }
`

export const Articles = (props) => {
  const [scrollY, setScrollY] = React.useState(-1)
  const hasFetchedMore = props.articles[props.section] ? props.articles[props.section].hasFetchedMore : false

  React.useEffect(() => {
    if (hasFetchedMore && scrollY >= 0) {
      const timer = setTimeout(() => {
        window.scrollTo(0, scrollY)
        setScrollY(-1)
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [hasFetchedMore])

  return (
    <IONArticles
      moreButton={(p) => (
        <x.div id='buttonContainer' className='more-from-btn noScript' display='flex' justifyContent='center' alignItems='center' w='100%' gridColumn='span 4' my='20'>
          {typeof window !== 'undefined' && (
            <MoreFromButton
              className={'btn' + p.moreButtonColour} id='viewMoreButton'
              onClick={() => {
                props.pageView(p.canonical.replace(process.env.RAZZLE_SITE_URL, ''))
                setScrollY(window.pageYOffset)
                return p.onClick()
              }}
            >More From {p.sectionProps.sectionsLabels && p.sectionProps.sectionsLabels[0] && p.sectionProps.sectionsLabels[0].label}
            </MoreFromButton>
          )}
          {typeof window === 'undefined' && (
            <a id='viewMoreButtonNoScript' href={p.nextHref}><MoreFromButton>More From {p.sectionProps.sectionsLabels && p.sectionProps.sectionsLabels[0] && p.sectionProps.sectionsLabels[0].label}</MoreFromButton></a>
          )}
          <Helmet>
            <link rel='canonical' itemprop='url' href={p.canonical} />
            <link rel='next' href={p.nextHref} />
            {p.prevHref && (
              <link rel='prev' href={p.prevHref} />
            )}
          </Helmet>
        </x.div>
      )} {...props}
    />
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ pageView }, dispatch)
export default memo(connect(state => state.app, mapDispatchToProps)(Articles), deepCompare)
