import React from 'react'
import { x } from '@xstyled/styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchMostReadArticles } from '../../store/mostReadArticles'

import { getCanonical, getTeaser } from '../../lib/articleUtils'
import StyledLink from '../components/ui/Link'
import { Articles } from 'ion-article-cmp'
import NoImage from '../../../assets/images/general/no-image.png'
import { getVisitorId, getSessionId } from '../../store/article'

export const Skeleton = (props) => (
  Array(5).fill().map((v, i) =>
    <x.article key={i} pb={{ _: '20px', lastType: '0' }} mb={{ _: '20px', lastType: '0' }} borderWidth={{ _: '0 0 1px 0', lastType: '0' }} borderStyle='solid' borderColor='divider'>
      <x.div h={5} mb={5} bg='#F0F0F0' />
      <x.div w='33%' h={5} bg='#F0F0F0' />
    </x.article>
  )
)

const MostReadArticles = (props) => {
  const clickSource = 'Most Read'
  return (
    <Articles
      className={props.className} loading={Skeleton} section={props.section || 'news'} page0Limit={props.pageSize || 5} pageSize={props.pageSize || 5} fetchArticles={props.fetchMostReadArticles}
      noImage={NoImage} notFound={() => (<Skeleton />)} fetchMoreArticles={() => { }} articles={props.articles}
      component={({ articles }) => {
        return articles.map((article, index) => (
          <x.article key={article.uuid + '-' + index} pb={{ _: '20px', lastType: '0' }} mb={{ _: '20px', lastType: '0' }} borderWidth={{ _: '0 0 1px 0', lastType: '0' }} borderStyle='solid' borderColor='divider'>
            <StyledLink to={'/' + getCanonical(article)} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}>
              <x.h6 text='h6'>{getTeaser(article)}</x.h6>
            </StyledLink>
          </x.article>
        ))
      }}
    />
  )
}

MostReadArticles.propTypes = {
  articles: PropTypes.object.isRequired,
  fetchMostReadArticles: PropTypes.func.isRequired
}

MostReadArticles.defaultProps = {
  pageSize: 5
}

const mapStateToProps = (state) => ({ articles: state.mostReadArticles, visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchMostReadArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MostReadArticles)
