import React, { useCallback } from 'react'
import { x } from '@xstyled/styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setWallPaperActive } from '../../store/app'
import Ad from '../components/Ad'
import {FORMATS, MAPPINGS} from './AdFormats'
import { useLocation } from 'react-router-dom'

const wallpaperMapping = [
  [[1500, 0], [1, 1]],
  [[1400, 0], [1, 1]],
  [[1024, 0], []],
  [[640, 0], []],
  [[0, 0], []]
]

const WingBanners = ({ isConnected, adPath, mappingLeft, mappingRight, onSlotRenderEndedLeft, onSlotRenderEndedRight, wallpaperActive, setWallPaperActive }) => {
  const handleWallpaperLoaded = useCallback((e) => {
    setWallPaperActive(!e.isEmpty)
  })
  const { pathname } = useLocation()
  return (
    <>
      <x.section w='100vw' h='100%' position='absolute' left='50%' mt={20} top='0' transform='translateX(-50%)' zIndex='1' display={wallpaperActive ? 'none' : 'block'} visibility={wallpaperActive ? 'hidden' : 'visible'}>
        {/* Only request Wallpapers on the home page */}
        {(pathname === '/' || pathname === '/business' || pathname.startsWith('/business-report')) && process.env.RAZZLE_ENABLE_WALLPAPER &&
          <Ad isConnected={isConnected} adPath={adPath} collapseEmptyDiv slotId='wallpaper' targeting={{ Wallpaper: '1' }} mapping={wallpaperMapping} className='wallpaper-advert' {...FORMATS.wallpaper} onSlotRenderEnded={handleWallpaperLoaded} />}
        <x.div position='absolute' left={20} top={0} display={{ wAdM: 'none', wAdD: 'block' }} h='100%'>
          <x.div position='sticky' top={20}>
            <Ad isConnected={isConnected} adPath={adPath} slotId='Wingbanner-Left' targeting={{ Wingbanner: 'Left' }} onSlotRenderEnded={onSlotRenderEndedLeft} collapseEmptyDiv mapping={mappingLeft} {...FORMATS.wingbanner} />
          </x.div>
        </x.div>
        <x.div position='absolute' right={20} top={0} display={{ wAdM: 'none', wAdD: 'block' }} h='100%'>
          <x.div position='sticky' top={20}>
            <Ad isConnected={isConnected} adPath={adPath} slotId='Wingbanner-Right' targeting={{ Wingbanner: 'Right' }} onSlotRenderEnded={onSlotRenderEndedRight} collapseEmptyDiv mapping={mappingRight} {...FORMATS.wingbanner} />
          </x.div>
        </x.div>
      </x.section>
    </>
  )
}

const mapStateToProps = (state) => (state.app)
const mapDispatchToProps = (dispatch) => bindActionCreators({ setWallPaperActive }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WingBanners)
