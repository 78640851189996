import React, { cloneElement, forwardRef, useEffect, useState, useRef } from 'react'
import { bool, func, node, number, string } from 'prop-types'
import { useDown } from '@xstyled/styled-components'
import { useSwipeable } from 'react-swipeable'

import * as S from './Carousel.style'

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const Carousel = forwardRef((props, ref) => {
  const {
    children,
    dataTestId,
    goNext,
    goPrev,
    id = 'carousel',
    loop,
    nextButton,
    numberOfSlides,
    pageIdx,
    prevButton,
    renderPaginationItem,
    setNumberOfSlides,
    setPageIdx,
    slidesToShow,
    slidesToSwipe,
    swipeHandlers,
    ...rest
  } = props

  const translateX = -(pageIdx * 100)

  useEffect(() => {
    // TODO: Allow for immutable objects - remove when no longer needed
    setNumberOfSlides(children.length || children.size)
  }, [children, setNumberOfSlides])

  // Get array with indexes of visible slides so we know which ones are (aria-)hidden
  const visibleSlides = Array(slidesToShow)
    .fill()
    .map((_, idx) => pageIdx + idx)

  return (
    <>
      {prevButton && (
        <S.Prev
          aria-controls={id}
          aria-label="Previous slide"
          className="carousel-prev"
          data-testid={dataTestId && `${dataTestId}-button-prev`}
          disabled={!loop && pageIdx === 0}
          onClick={goPrev}
        >
          {prevButton}
        </S.Prev>
      )}
      {nextButton && (
        <S.Next
          aria-controls={id}
          aria-label="Next slide"
          className="carousel-next"
          data-testid={dataTestId && `${dataTestId}-button-next`}
          disabled={!loop && pageIdx >= numberOfSlides - slidesToSwipe}
          onClick={goNext}
        >
          {nextButton}
        </S.Next>
      )}
      <S.Wrapper
        {...rest}
        aria-live="off"
        aria-roledescription="carousel"
        id={id}
        ref={ref}
        role="region"
      >
        <S.Carousel {...swipeHandlers} slidesToShow={slidesToShow} translateX={translateX}>
          {children.map((child, idx) =>
            cloneElement(child, {
              key: idx,
              role: 'group',
              'aria-hidden': !visibleSlides.includes(idx),
              'aria-readonly': true,
              'aria-roledescription': 'slide',
              'aria-label': `${idx + 1} of ${numberOfSlides}`
            })
          )}
        </S.Carousel>
        <S.Pagination
          className="carousel-pagination"
          data-testid={dataTestId && `${dataTestId}-pagination`}
        >
          {children.map((_, idx) => {
            const props = {
              idx,
              'aria-controls': id,
              'aria-label': `${idx + 1} of ${numberOfSlides}`,
              'aria-selected': idx === pageIdx,
              onClick: () => setPageIdx(idx),
              pageIdx
            }
            if (renderPaginationItem) {
              return renderPaginationItem(props)
            }
            // eslint-disable-next-line react/no-array-index-key
            return <S.Bullet active={idx === pageIdx} key={idx} {...props} />
          })}
        </S.Pagination>
      </S.Wrapper>
    </>
  )
})

Carousel.Slide = S.Slide
Carousel.Bullet = S.Bullet
Carousel.displayName = 'Carousel'

export const useCarousel = (props = {}) => {
  let {
    autoplay,
    duration = 5000,
    loop,
    slidesToShow = 1,
    slidesToSwipe = slidesToShow,
    mobileSlidesToShow = 1,
    mobileSlidesToSwipe = mobileSlidesToShow,
    onSelectSlide,
    ...rest
  } = props

  // Set slidesToShow to 1 for mobile
  const downMd = useDown('md')
  if (downMd) {
    slidesToShow = mobileSlidesToShow
    slidesToSwipe = mobileSlidesToSwipe
  }

  const [numberOfSlides, setNumberOfSlides] = useState(0)
  const [pageIdx, setPageIdx] = useState(0)

  const lastSlideIdx = numberOfSlides ? numberOfSlides - slidesToShow : 0

  // Add autoplay
  useInterval(
    () => {
      if (autoplay) {
        goNext()
      }
    },
    autoplay ? duration : null
  )

  const goNext = () => {
    const nextPageIdx = Math.min(pageIdx + slidesToSwipe, lastSlideIdx)

    if (pageIdx === lastSlideIdx && loop) {
      setPageIdx(0)
      if (onSelectSlide) {
        onSelectSlide(0)
      }
    } else if (nextPageIdx <= lastSlideIdx) {
      setPageIdx(nextPageIdx)
      if (onSelectSlide) {
        onSelectSlide(nextPageIdx)
      }
    }
  }

  const goPrev = () => {
    const prevPageIdx = Math.max(pageIdx - slidesToSwipe, 0)

    if (pageIdx === 0 && loop) {
      setPageIdx(lastSlideIdx)
      if (onSelectSlide) {
        onSelectSlide(lastSlideIdx)
      }
    } else if (prevPageIdx >= 0) {
      setPageIdx(prevPageIdx)
      if (onSelectSlide) {
        onSelectSlide(prevPageIdx)
      }
    }
  }

  const swipeHandlers  = useSwipeable({
    onSwipedLeft: goNext,
    onSwipedRight: goPrev
  })

  return {
    goNext,
    goPrev,
    loop,
    numberOfSlides,
    pageIdx,
    setNumberOfSlides,
    setPageIdx,
    slidesToShow,
    slidesToSwipe,
    swipeHandlers,
    ...rest
  }
}

Carousel.propTypes /* remove-proptypes */ = {
  autoplay: bool,
  children: node,
  dataTestId: string,
  duration: number,
  goNext: func,
  goPrev: func,
  id: string,
  loop: bool,
  nextButton: node,
  numberOfSlides: number,
  pageIdx: number,
  prevButton: node,
  renderPaginationItem: func,
  setNumberOfSlides: func,
  setPageIdx: func,
  slidesToShow: number,
  slidesToSwipe: number
}